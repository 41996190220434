import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { Routes, Route} from 'react-router-dom';

import { setupPublicAxios } from './configs/axios';

import RegisterAgent from './views/Register';
import SuccessPage from './views/SuccessPage'
import paths from './utils/paths';




setupPublicAxios(process.env.REACT_APP_BASE_URL);

export const App = () => {

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path={paths.home} element={<RegisterAgent />} />
        <Route path={paths.accountCreated} element={<SuccessPage />} />
      </Routes>
    </ChakraProvider>
  );
};


