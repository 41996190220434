import {  useMutation } from "@tanstack/react-query";
import {  useNavigate} from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { useToast } from "@chakra-ui/react";
import services from "./services";
import paths from "../../utils/paths";






export const useRegisterAgent = () => {
  const toast = useToast();
  const navigate = useNavigate();


  return useMutation(services.login, {
    onError: (data: AxiosError) => {
      const errObj: ErrorObj = data.response!.data as ErrorObj;
      toast({
        title: "Invalid Credential",
        description: errObj.errorMsg,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    },
    onSuccess: (data:  AxiosResponse) => {
      navigate(paths.accountCreated);
    },
  });
};
