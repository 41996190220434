import {
  Box,
  Button,
  Center,
  Heading,
  VStack,
  Image,
  Text,
} from '@chakra-ui/react';

const Index = () => {
  return (
    <Box p={4} width='100vw'>
      <Center h='95%'>
        <VStack width={{ base: '100%', lg: '60%' }} spacing={6}>
          <Image
            src='/images/afrimash.svg'
            boxSize={{ base: '100px', md: '10vw' }}
          />
          <Heading as='h1' size='lg' noOfLines={2}>
            Afrimash Agent
          </Heading>
          <Box p={6} textAlign='center' boxShadow='md' borderRadius='md'>
            <Text fontWeight='bold' fontSize='xl'>
              Account Created
            </Text>
            <Text mt={4}>
              Your registration was successful. Please check your email or SMS
              inbox for your login details. You can now access the app and start
              using shopping for farmers. <br />{' '}
              <strong>
                please click on the button below to download the mobile app
              </strong>
            </Text>
            <Button
              mt={4}
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.afrimash_agent.afrimash_agent',
                );
              }}
            >
              Get Agent App
            </Button>
          </Box>
        </VStack>
      </Center>
    </Box>
  );
};

export default Index;
