import { useMemo } from 'react';
import {
  Grid,
  GridItem,
  Center,
  Heading,
  VStack,
  Image,
  Button,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import generateInputs from '../../components/DynamicForm';
import statesInNigeria from '../../utils/statesInNig';
import getAgeBracket from '../../utils/getAgeBracket';

import { useRegisterAgent } from './queryHooks';

type Props = {};

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    mobileNo: yup.number().required(),
    email: yup.string().required(),
    ageBracket: yup.string().required(),
    gender: yup.string().required(),
    state: yup.string().required(),
    agentType: yup.string().required(),
  })
  .required();

type FormValues = Record<string, string>;

const SignIn = (props: Props) => {
  const { mutate, isLoading } = useRegisterAgent();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  const inputObj: InputObj[] = useMemo(
    () => [
      {
        name: 'firstName',
        label: 'First Name',
        placeholder: 'Your first name',
        type: 'text',
        register: register('firstName', {
          required: 'Please enter your first name',
        }),
        isInvalid: !!errors.firstName,
        error: errors?.firstName,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        register: register('lastName', {
          required: 'Please enter your last name',
        }),
        isInvalid: !!errors.lastName,
        error: errors?.lastName,
      },
      {
        name: 'mobileNo',
        label: 'Mobile No',
        type: 'number',
        register: register('mobileNo', {
          required: 'Please enter your last name',
          pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
        }),
        isInvalid: !!errors.mobileNo,
        error: errors?.mobileNo,
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        register: register('email', {
          required: 'Please enter your last name',
        }),
        isInvalid: !!errors.email,
        error: errors?.email,
      },
      {
        name: 'state',
        label: 'State',
        type: 'select',
        register: register('state', {
          required: 'Please enter your last name',
        }),
        options: statesInNigeria.map((item) => ({ value: item, name: item })),
        isInvalid: !!errors.state,
        error: errors?.state,
      },
      {
        name: 'gender',
        label: 'Gender',
        type: 'select',
        register: register('gender', {
          required: 'Please enter your last name',
        }),
        options: ['Male', 'Female', 'Others'].map((item) => ({
          value: item.toUpperCase(),
          name: item,
        })),
        isInvalid: !!errors.gender,
        error: errors?.gender,
      },
      {
        name: 'ageBracket',
        label: 'Age',
        type: 'select',
        register: register('ageBracket', {
          required: 'Please enter your age bracket',
        }),
        options: ['18 - 24', '25 - 30', '31 - 40', '40+'].map((item) => ({
          value: getAgeBracket(item),
          name: item,
        })),
        isInvalid: !!errors.ageBracket,
        error: errors?.ageBracket,
      },
      {
        name: 'agenttype',
        label: 'Agent Type',
        type: 'select',
        register: register('agentType', {
          required: 'Please select an agent type',
        }),
        options: [
          { value: 'BD_AGENT', name: 'Business Development Agent(BDA)' },
        ],
        isInvalid: !!errors.agentType,
        error: errors?.agentType,
      },
    ],
    [
      errors.ageBracket,
      errors.agentType,
      errors.email,
      errors.firstName,
      errors.gender,
      errors.lastName,
      errors.mobileNo,
      errors.state,
      register,
    ],
  );

  const onSubmit = (data: FormValues) => {
    mutate({ data });
  };

  return (
    <Grid
      minH='100vh'
      templateRows='repeat(10, 1fr)'
      templateColumns='repeat(12, 1fr)'
    >
      <GridItem
        rowSpan={10}
        colSpan={{ base: 0, lg: 5 }}
        backgroundImage='url(/images/afrimashside2.png)'
        backgroundPosition='center'
        backgroundRepeat='no-repeat'
        backgroundSize='cover'
        display={{ base: 'none', lg: 'block' }}
      ></GridItem>
      <GridItem rowSpan={10} colSpan={{ base: 12, lg: 7 }} p={2}>
        <Center h='95%'>
          <VStack width={{ base: '100%', lg: '60%' }} spacing={6}>
            <Image
              src='/images/afrimash.svg'
              boxSize={{ base: '100px', md: '10vw' }}
            />
            <Heading as='h1' size='lg' noOfLines={2}>
              Register to Become an Agent
            </Heading>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              {inputObj.map((input) => generateInputs(input))}
              <Button mt='4' variant='full' type='submit' isLoading={isLoading}>
                Submit
              </Button>
            </form>
          </VStack>
        </Center>
      </GridItem>
    </Grid>
  );
};

export default SignIn;
