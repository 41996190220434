import { VscFileMedia } from 'react-icons/vsc';
import {FaWarehouse } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi';
import { GrUserWorker } from 'react-icons/gr';
import { BiWallet } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';

const paths = {
  home: '/',
  login: '/login',
  accountCreated: '/account-created',
  customers: '/customers',
  agents: '/agents',
  profile: '/profile',
  yourOrders: '/yourorders',
  wallet: '/wallet'
}


export const pageTitles = {
  dashboard: 'Dashboard',
  customers: 'Customers',
  agents: 'Agents',
  profile: 'Profile',
  wallet: 'Wallet',
  yourOrders: 'Your Orders'
}

export const NavNames = {
  dashboard: 'Dashboard',
  customers: 'Customers',
  agents: 'Agents',
  profile: 'Profile',
  wallet: 'Wallet',
  yourOrders: 'Your Orders'
}

export const pathObject = [
  { name: NavNames.dashboard, path: paths.home, icon: <FaWarehouse /> },
  { name: NavNames.agents, path: paths.agents, icon: <GrUserWorker /> },
  { name: NavNames.customers, path: paths.customers, icon: <FiUsers /> },
  { name: NavNames.yourOrders, path: paths.yourOrders, icon: <VscFileMedia /> },
  { name: NavNames.wallet, path: paths.wallet, icon: <BiWallet /> },
  { name: NavNames.profile, path: paths.profile, icon: <CgProfile /> }, 
]




export default paths