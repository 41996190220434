const getAgeBracket = (age: string) => {
    switch (age) {
        case '18 - 24':
            return 'EIGHTEEN_TO_TWENTYFOUR';
        case '25 - 30':
            return 'TWENTYFIVE_TO_THIRTY';
        case '31 - 40':
            return 'THIRTYONE_TO_FORTY';
        default:
            return 'ABOVE_FORTY';
    }
}

export const getAgeBracket_r = (age: string) => {
    switch (age) {
        case 'EIGHTEEN_TO_TWENTYFOUR':
            return '18 - 24';
        case 'TWENTYFIVE_TO_THIRTY':
            return '25 - 30';
        case 'THIRTYONE_TO_FORTY':
            return '31 - 40';
        default:
            return 'Above 40';
    }
}

export default getAgeBracket;