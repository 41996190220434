
import endpoints from '../../utils/endpoints';
import { publicAxios as axios } from '../../configs/axios';


class Services{
    login(payload : Params){
        return axios({
            method: 'POST',
            url: endpoints.registerAgent,
            data: payload.data
        })
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new Services()